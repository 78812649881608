export const FACEBOOK_LINK = "https://www.facebook.com/arivilloso";
export const PINTEREST_LINK = "https://www.pinterest.com/arivilloso/";
export const INSTAGRAM_LINK = "https://www.instagram.com/arivilloso_official/";
export const SHARE_LINK = "/";
export const EMAIL_SALES = "sales@arivilloso.com";
export const PHONE_SALES_TEXT = "+38(063) 236-79-10";
export const PHONE_SALES = "+380632367910";
// export const ADDRESS_SALES = "Shevchenko Str. 16, Chernihiv, Ukraine, 14001";
export const TELEGRAM_LOGIN = "arivilloso"
// export const MAIN_PAGE_IMAGE = "https://thumb.arivilloso.com/1920x1080/410c208d7bf0a941985958c93ec3d61c-16aeeed74239a9bb.jpg"
// export const MAIN_PAGE_IMAGE = "https://thumb.arivilloso.com/1920x1080/3d9a6a6401fa043c5b3e5a9b5c778837-84d7e96cff554532.jpg"
export const MAIN_PAGE_IMAGE = "https://thumb.arivilloso.com/1920x1080/3d9a6a6401fa043c5b3e5a9b5c778837-700d840d8101bef5.jpg"
export const S3_PATH = "https://files.arivilloso.com"
